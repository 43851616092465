import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/nl/analytics-consulting",
  "Data Layer": "/nl/data-laag"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

const DataLayer = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Data Laag uitgelegd met Tutorial [2020]"
        description="Een data laag is het fundamentele concept voor elke professionele web analytics setup. Maar wat is het eigenlijk en hoe implementeren we het?"
        lang="nl"
        image="data-layer-code-hero-image3"
        alternateLangs={alternateLangs}
        canonical="/nl/data-laag"
        datePublished="2024-06-10T04:32:43.188Z"
        dateModified="2024-06-10T15:21:48.881Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="data-layer/data-layer-code-hero-image3.png"
          alt="Visualisatie van de Google Tag Manager Data Laag in de browserconsole"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Data Laag</H>
        <p>
          In verband met tag management en web analytics, heb je misschien de term <b>data laag</b> gehoord. Het is het
          fundamentele element in een ambitieuze web analytics setup omdat alle datapunten en trackingregels ervan afhangen.
        </p>
        <p>
          Daarom wordt het in de digitale analytics wereld behandeld als een niet-onderhandelbare vereiste voor elke analytics
          setup. Echter, er bestaan ook scenario's waarin het niet nodig is.
        </p>
        <p>
          Daarom wil ik uitleggen <Link to="/nl/data-laag#wat-is-een-data-laag">wat een data laag is</Link>,{" "}
          de <Link to="/nl/data-laag#voordelen-van-data-laag">voordelen</Link>, en de verschillen tussen de{" "}
          <Link to="/nl/data-laag#data-laag-voor-google-tag-manager">data laag voor Google Tag Manager</Link> en{" "}
          <Link to="/nl/data-laag#data-laag-voor-adobe-analytics">Adobe Launch</Link>.
        </p>
        <p>
          Daarna gaan we kijken naar de implementatie voor de meest populaire Tag Management Systemen (TMS). Ik zal
          de <Link to="/nl/data-laag#1">ontwerp</Link> fase uitleggen, gevolgd door de{" "}
          <Link to="/nl/data-laag#2">implementatie</Link> en <Link to="/nl/data-laag#3">debugging</Link>.{" "}
        </p>
        <H as="h2">Wat is een data laag?</H>
        <p>Een Data Laag is een <b>datastructuur</b> die relevante informatie in Key-Value Pairs biedt voor gebruik met bijvoorbeeld <Link to="/nl/tagbeheer">Tag Management Systemen</Link>.</p>
        <p>Een Data Laag is beschikbaar in de globale JavaScript Scope van de website als een <b>Array</b> of <b>Object</b> en bevat data in een gestructureerde vorm voor gebruik door andere programma's.</p>
        <p>Het voordeel van een Data Laag ligt in een programmatisch eenvoudige toegang tot relevante data tijdens een websitebezoek.</p>

        <p>
          Het maakt toegang tot data mogelijk op één centraal punt en is de basis voor de data analytics logica in een <Link to="/nl/tagbeheer#wat-is-een-tag-management-systeem">tag
            management systeem</Link>.
        </p>
        <SnippetJS caption="Code snippet om een datalaag te maken voor Google Tag Manager. Het creëert een JavaScript-Object in een array (!) die alle relevante dimensies bevat voor latere data-analyse en regelcreatie in het tag management systeem.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "categoriepagina",
  "pageName": "sneakeroverzicht",
  "language": "nl-NL",
}];`}
        </SnippetJS>
        <p>
          Omdat het wordt gebruikt om data van meerdere databronnen op te slaan, vereenvoudigt het het monitoren van huidige datapunten,
          omdat slechts één enkele plaats moet worden geobserveerd ("single point of truth").
        </p>
        <p>
          Een data laag wordt <b>opnieuw opgebouwd bij elke paginalading</b> en uitgerust met datapunten van de huidige webpagina en
          mogelijk andere gerelateerde data over de bezoeker en zijn bezoek.
        </p>
        <p>
          <b>Let op:</b> Single page applications (SPA) laden de pagina niet opnieuw tussen de paginanavigatie door. Daarom is de
          configuratie van de data laag voor een single page application anders dan voor typische webpagina's met paginaladingen.
        </p>
        <p>
          De opgeslagen data vertegenwoordigt kenmerken of eigenschappen van een subpagina en wordt opgeslagen in een key-value-pair. De sleutels
          bevatten beschrijvende namen van de eigenschappen, gekoppeld aan een huidige waarde, die doorgaans verandert tijdens de gebruikersreis.
        </p>
        <SnippetJS caption="De relevante kenmerken van een subpagina, zoals de categorie, naam en taal, worden opgeslagen in variabelen en kunnen later worden gebruikt voor analyse of regelcreatie in het TMS.">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "categoriepagina", //categorie
  "pageName": "sneakeroverzicht", //naam
  "language": "nl-NL", //taal
}];`}
        </SnippetJS>
        <p>
          Het algemene doel is om die datapunten beschikbaar te maken in het tag management systeem, zodat ze kunnen
          worden verzonden samen met de data die wordt gedeeld met bijvoorbeeld Google Analytics of Facebook Ads om
          website-interacties beter te beschrijven.
        </p>
        <p>
          Om deze integratie mogelijk te maken, houdt het TMS verwijzingen naar de key-value-pairs en kan bijvoorbeeld
          regels uitvoeren zodra hun waarde verandert.
        </p>
        <p>
          <b>Voorbeeld:</b> Een bezoeker zoomt in op een productafbeelding en triggert daarmee een event "product zoom".
          Zonder aanvullende data die samen met het event wordt verzonden, is het niet erg inzichtelijk. Daarom sturen we
          ook data over bijvoorbeeld de productnaam, categorie en prijs, zodat we het event in een betekenisvolle context
          kunnen analyseren.
        </p>
        <p>
          De aanvullende data zou het mogelijk maken om te verifiëren of dergelijke productzooms alleen voorkomen in bepaalde
          productcategorieën. Als dat zo is, kan het nuttig zijn om meer afbeeldingen toe te voegen aan andere producten in
          dezelfde categorie, omdat bezoekers blijkbaar erg geïnteresseerd zijn in de afbeeldingsdetails van die producten.
        </p>
        <p>
          Het komt erop neer dat we alle relevante data structureren in beschrijvende sleutels en waarden en deze
          beschikbaar maken op een centrale plek waar ze gemakkelijk kunnen worden opgepikt.
        </p>
        <p>
          Dergelijke datapunten zijn meestal belangrijke kenmerken van de pagina-inhoud of een classificatie die we hebben
          bedacht om bezoekers te segmenteren op basis van gedrag.
        </p>
        <p>
          Om beter te begrijpen wat een data laag is, kun je je als een vereenvoudigde visualisatie een Excel-sheet
          voorstellen. De sheet bevat belangrijke kenmerken van een webpagina in de kop (pad, taal, categorie,
          ingelogde status) samen met een huidige waarde voor elk item.
        </p>
        <ImgContainerFixed width="449px">
          <ImgScreenshot
            src="data-layer/data-layer-structure-excel-example.png"
            alt="Vereenvoudigd voorbeeld van een data laag in Excel"
            className="article-img"
            caption="Je kunt een data laag voorstellen als een eenvoudige tabel met kolomkoppen"
          />
        </ImgContainerFixed>
        <H as="h2">Wat zijn de voordelen van het gebruik van een data laag?</H>
        <p>
          Terwijl een bezoeker door een website navigeert, vinden er veel gebruikersinteracties plaats: klikken op
          knoppen, ingevulde formulieren of bekeken video's.
        </p>
        <p>
          Als die interacties ons in staat stellen conclusies te trekken over de gebruikersbetrokkenheid, worden ze
          verzonden naar bijvoorbeeld Google Analytics samen met andere beschrijvende data over de bezoeker, sessie, het
          event zelf of het HTML-element waarmee ze interacteerden.
        </p>
        <p>
          <u>En dit is het cruciale punt</u>: De aanvullende data om dergelijke interacties te beschrijven komt uit
          verschillende databronnen, bijvoorbeeld van de <b>frontend, database</b> of een externe <b>API</b>.
        </p>
        <p>
          Om de voordelen van een data laag te begrijpen, moeten we eerst de uitdagingen begrijpen die ontstaan wanneer we
          data van meerdere databronnen nodig hebben.
        </p>
        <p>Laten we kijken naar een voorbeeld waarbij we data verzamelen van dergelijke bronnen en laten we het doordenken:</p>
        <p>
          <b>Voorbeeld:</b> De bezoeker koopt een product op een website. De volgende dimensies kunnen van belang zijn:
        </p>
        <ul
        >
          <li>productnaam</li>
          <li>productprijs</li>
          <li>productmaat</li>
          <li>productcategorie</li>
          <li>productkleur</li>
          <li>winkelmandwaarde</li>
          <li>merk</li>
          <li>eerste aankoop</li>
          <li>klantsegment</li>
          <li>klantkorting</li>
          <li>geslacht</li>
          <li>land</li>
        </ul>
        <p>
          Na de aankoop landen bezoekers op een bedankpagina met alle details van de aankoop en het bezorgadres.
        </p>
        <p>
          <b>Frontend</b>: Om de productdata, winkelmandwaarde en merk samen met het event te verzenden, kunnen we het
          eventueel van de bedankpagina scrapen.
        </p>
        <p>
          De grootste uitdaging van het scrapen van data van een webpagina is dat de data beschikbaar moet zijn op elke
          pagina waar de interactie plaatsvindt. Dit is in de praktijk zelden het geval.
        </p>
        <p>
          Het is raadzaam om zoveel mogelijk dezelfde dimensies te meten bij alle interacties op een website, zodat de
          interacties later tijdens data-analyse vergelijkbaar zijn. Dus, als we die benadering zouden volgen, is het
          waarschijnlijk dat andere pagina's geen <i>productdata, winkelmandwaarde en merknaam</i> vermelden om mee te
          sturen met andere events.
        </p>
        <p>
          Dus als de benodigde data niet beschikbaar is op andere pagina's, willen we vermijden dat we al die data aan
          de content toevoegen enkel en alleen voor analytics. <b>Daarom gebruiken we een data laag</b>. Het maakt de
          data voor ons beschikbaar om te verzamelen, ongeacht of het zichtbaar is op de pagina of niet. Het is
          letterlijk een laag van data die bovenop elke subpagina zit en de data levert die we nodig hebben.
        </p>
        <p>
          Een andere uitdaging van het scrapen van data van de frontend is dat het uiteindelijk breekt. Wanneer pagina's
          worden gewijzigd en die wijzigingen de HTML-structuur van de gescrapete elementen beïnvloeden, dan zal de
          dataverzameling breken. Vooral in grotere bedrijven zijn er frequente wijzigingen op pagina's en werken er
          meerdere teams aan zonder te weten of sommige HTML-elementen nodig zijn voor dataverzameling. Daarom zal elke
          scraping van data van de frontend op een gegeven moment breken op vaak bijgewerkte websites.
        </p>
        <p>
          Een data laag benut deze aanpak en maakt het mogelijk om gebruikersdata op een veilige en toch eenvoudige
          manier op te halen.
        </p>
        <p>
          <b>Database</b>: Het verzamelen van klantdata (klantsegment, korting, geslacht en eerste aankoop) kan
          ingewikkeld worden: Klantdata zou ofwel met de serverrespons moeten worden meegestuurd of met een aparte API.
        </p>
        <p>
          Maar aangezien dit privégegevens zijn, moet de verzameling worden geauthenticeerd voor gegevensbeschermingsdoeleinden.
          Dat betekent dat een API-verzoek niet in de browser kan worden afgehandeld omdat de API-sleutel anders
          door ervaren gebruikers zou kunnen worden opgehaald.
        </p>
        <p>
          Daarom is de beste oplossing om de data samen met de serverrespons te verzenden op basis van de
          inlogauthenticatie van de website. <br />
          Wanneer de gebruiker is ingelogd, wordt de data laag gevuld met de relevante data uit de database. Zonder
          inloggen worden er geen gevoelige gegevens blootgesteld.
        </p>
        <p>
          <b>API</b>: Geo data zoals het land kan worden opgehaald via een externe service API.
        </p>
        <p>
          Echter, dezelfde uitdaging als bij het ophalen van data uit de database doet zich voor: Elke API-aanvraag van de
          frontend of het tag management systeem vereist een API-sleutel, die om veiligheidsredenen niet in de browser
          moet worden behandeld.
        </p>
        <p>
          Een ander nadeel van werken met API's voor dataverzameling, vooral bij events, is de duur totdat de data terugkomt.
          Als een gebruiker naar een andere pagina navigeert voordat de data is aangekomen, riskeren we het event te
          verliezen.
        </p>
        <p>Laten we de voordelen snel samenvatten:</p>
        <H as="h3">Voordelen</H>
        <ul>
          <li>Data is beschikbaar, ongeacht of het zichtbaar is op de pagina</li>
          <li>Robuuste dataverzameling</li>
          <li>Veilige verzameling van gevoelige data</li>
          <li>Verminderen van dataverlies bij asynchrone data-aanvragen</li>
        </ul>
        <H as="h2">Waarom je waarschijnlijk een data laag nodig hebt</H>
        <p>
          Door een data laag te creëren, wordt een JavaScript-object beschikbaar gemaakt in de globale scope van de
          browser bij elke paginalading.
        </p>
        <p>
          De data die het bevat kan afkomstig zijn van je database, frontend of API's, zodat dataverzameling van die bronnen
          betrouwbaar, veilig en onafhankelijk van de HTML op de pagina wordt.
        </p>
        <p>
          Data uit de database kan zonder veel moeite beschikbaar worden gemaakt op elke subpagina van de site zonder
          zichtbaar te zijn in de content.
        </p>
        <p>
          Om bovenstaande redenen adviseer ik klanten over het algemeen om data lagen te implementeren als ze serieus zijn
          over hun data-analyse ambities. De voordelen van datakwaliteit, betrouwbaarheid en de bijbehorende
          tijdsbesparing op de lange termijn rechtvaardigen de hogere implementatie-inspanningen.
        </p>
        <p>
          Het uiteindelijke doel van webanalytics is om datagestuurde zakelijke beslissingen te nemen, dus datakwaliteit
          moet een prioriteit zijn.
        </p>
        <p>
          Laten we nu kijken naar de verschillende beschikbare opties en enkele implementatievoorbeelden voordat we
          de ontwerp- en implementatiefase induiken.
        </p>
        <H as="h2">Data Laag voor Adobe Analytics, DTM, Launch en Tealium</H>
        <p>
          Data lagen kunnen verschillende structuren hebben. Over het algemeen maken we onderscheid tussen degenen met een{" "}
          <b>objectgebaseerde structuur</b> en een <b>arraygebaseerde structuur</b>.
        </p>
        <p>
          Volgens de{" "}
          <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
            data laag definitie
          </a>{" "}
          van het World Wide Web Consortium (W3C) volgt de syntaxis die van een JavaScript-object. Het wordt
          onofficieel afgekort als <b>CEDDL</b> (Customer Experience Digital Data Layer).
        </p>
        <p>Je kunt er ook andere objecten of arrays in nesten:</p>
        <SnippetJS caption="Voorbeeld van een data laag voor Adobe Analytics met een objectgebaseerde structuur, volgens de W3C-definitie.">
          {`
window.digitalData = {
  pageName: "sneakeroverzicht",
  destinationPath: "/nl/sneakers",
  breadCrumbs: ["home","sneakers"],
  publishDate: "2020-07-01",
  language: "nl-NL"
};`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics, Adobe Launch</b> en Tealium volgen de CEDDL-structuur. In het bovenstaande voorbeeld slaan we
          data op in een object genaamd <code>digitalData</code>. De naam is niet gestandaardiseerd en kan vrij worden
          gekozen, maar je moet de naam wel declareren in het tag management systeem.
        </p>
        <p>
          Om de data te wijzigen, zijn er meerdere opties (zoals bij elk JS-object), maar de eenvoudigste manier is om
          gewoon de waarden te overschrijven:
        </p>
        <SnippetJS caption="Om data toe te voegen aan de data laag, overschrijf eenvoudig bestaande data zoals bij elk normaal JavaScript-object.">
          {`window.digitalData.language = "de-DE";`}
        </SnippetJS>
        <p>
          Het centrale idee van de objectgebaseerde structuur is dat ze eenmaal per paginalading worden geladen, maar ze
          worden niet veel gewijzigd op basis van gebruikersinteractie. De data is meestal <b>statisch</b>.
        </p>
        <p>
          Event tracking wordt niet gedreven door events die het data laag object binnenkomen. Events worden getrackt met een{" "}
          <b>aparte trackingbibliotheek</b> om ze verder te sturen naar een analytics platform, bijvoorbeeld Adobe Analytics.
          Wanneer de event tracking code wordt uitgevoerd, wordt het data laag object in zijn geheel meegestuurd en kan
          worden gebruikt tijdens data-analyse.
        </p>
        <SnippetJS caption="Event tracking voor Adobe met een objectgebaseerde data laag structuur wordt afgehandeld via de _satellite-bibliotheek.">
          {`
//Event met gekozen kleur
_satellite.setVar("sneaker kleur", "zwart");
_satellite.track("selecteer kleur"); 

`}
        </SnippetJS>
        <H as="h2">Gebruik Adobe Launch met een array-gebaseerde data laag</H>
        <p>
          Je kunt Adobe Launch ook gemakkelijk gebruiken met een array-gebaseerde structuur. De Adobe Launch Extension{" "}
          <b>Data Layer Manager</b> maakt dit mogelijk.
        </p>
        <p>Hier zijn enkele links naar verdere bronnen voor het gebruik van de array-gebaseerde versie met Adobe Launch:</p>
        <ul>
          <li>
            Jim Gordon’s{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Demo van het gebruik van Data Layer Manager met Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extensie met{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              documentatie
            </a>
          </li>
        </ul>
        <H as="h2">Data Laag voor Google Tag Manager, Matomo en Piwik Pro</H>
        <p>
          De <b>data laag voor Google Tag Manager</b>, Matomo en Piwik Pro is array-gebaseerd en wordt onofficieel
          aangeduid als de event-driven data layer <b>(EDDL)</b>.
        </p>
        <p>
          Data wordt ook in objecten verwerkt, maar de algemene structuur van de GTM data laag is een <b>array met objecten</b>.
        </p>
        <SnippetJS caption="Code snippet voor het toevoegen van een GTM data laag">
          {`
window.dataLayer = window.dataLayer || [{
  "pageCategory": "categoriepagina",
  "pageName": "sneakeroverzicht",
  "language": "nl-NL",
}];
`}
        </SnippetJS>
        <p>
          De tracking-logica met een array-gebaseerde structuur is anders: Nieuwe data of wijzigingen worden erin gepusht via{" "}
          <code>dataLayer.push()</code>. Een <b>push naar de data laag</b> kan vervolgens tag-uitvoeringen in het tag management systeem triggeren.
        </p>
        <p>
          Het fundamentele verschil met een objectgebaseerde structuur is dat wijzigingen meestal samen met een
          event worden verzonden en dat regels worden getriggerd op basis van die wijzigingen zonder enige extra bibliotheek, alleen door te observeren
          of de data laag array verandert.
          <br />
          Aangezien er geen andere bibliotheek zoals <code>_satellite</code> nodig is, hebben we één afhankelijkheid minder.
        </p>
        <p>
          Een ander kenmerk van de array-gebaseerde benadering is dat de data vrij vaak verandert gedurende de
          gebruikersreis, omdat elke gebruikersinteractie de data laag variabelen kan wijzigen.
        </p>{" "}
        <p>
          Dus een array-gebaseerde data laag is de basis voor event tracking en verwerkt data flexibeler, terwijl een
          objectgebaseerde eerder dient als een statische datastorage.
        </p>
        <p>
          Door die flexibiliteit wordt gezegd dat een array-gebaseerde data laag structuur geschikter is voor
          Single-Page-Applications. <br />
          Je kunt echter ook SPAs tracken met een objectgebaseerde data laag, het vereist alleen een paar extra regels
          code en mogelijk enkele edge cases om op te lossen.
        </p>
        <p>
          Als je aan het begin van een project staat en de keuze hebt, geef ik de voorkeur aan een array-gebaseerde data laag.
          <br />
          Het veranderen van een al bestaande setup van een objectstructuur naar een array is echter onnodig.
        </p>
        <H as="h2">Content Management Systemen met ingebouwde data laag</H>
        <p>
          <b>WordPress</b>-gebruikers hebben het gemakkelijk omdat ze{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
            deze plugin
          </a>{" "}
          kunnen gebruiken om Google Tag Manager te implementeren samen met een vooraf geconfigureerde data laag.
        </p>
        <p>Het vult automatisch categorieën, auteursnamen, publicatiedata en zoektermen in.</p>
        <p>
          De datapunten kunnen worden aangevinkt of uitgevinkt in de plugin-instellingen. Verder biedt de plugin
          vooraf geconfigureerde events voor formulierinzendingen van de meest voorkomende formplugins.
        </p>
        <p>
          Als je een webshop eigenaar bent en WooCommerce voor WordPress gebruikt, kun je een{" "}
          <b>klassieke e-commerce data laag</b> evenals een <b>verbeterde e-commerce data laag</b> implementeren met dezelfde
          plugin, wat behoorlijk krachtig is.
        </p>
        <p>
          <b>WordPress</b>-gebruikers die <b>Tealium</b> willen gebruiken, kunnen een plugin voor{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
            Tealium
          </a>
          gebruiken.
        </p>
        <p>
          <b>Drupal</b> heeft ook een{" "}
          <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
            plugin
          </a>
          .
        </p>
        <p>
          <b>Wix</b> en <b>Squarespace</b>-gebruikers kunnen Google Tag Manager implementeren via de platformtools maar moeten
          de data laag code handmatig implementeren.
        </p>
        <H as="h2">Implementatie van de data laag</H>
        <p>
          Dus hoe implementeer je een data laag? - Aangezien planning en implementatie kennis vereisen op het gebied van
          digitale analytics, frontend ontwikkeling en backend ontwikkeling, wordt de implementatie meestal uitgevoerd
          door een webbureau samen met een{" "}
          <Link to="/nl/google-analytics-consultant">analytics consultant</Link>.
        </p>
        <p>
          De analytics consultant informeert het webbureau en leidt het project totdat de implementatie succesvol is
          gevalideerd. Vervolgens worden het tag management systeem en de analytics tools geconfigureerd.
        </p>
        <p>
          Als je geïnteresseerd bent en wat JavaScript-kennis hebt, kun je het zelf implementeren met de volgende
          <b>implementatiegids</b>.
        </p>
        <p>De implementatie doorloopt 3 stappen:</p>
        <ol>
          <li>
            <Link to="/nl/data-laag#1">Data Laag Ontwerp</Link>
          </li>

          <li>
            <Link to="/nl/data-laag#2">Implementatie</Link>
          </li>

          <li>
            <Link to="/nl/data-laag#3">Debugging</Link>
          </li>
        </ol>
        <H as="h3">1. Data Laag Ontwerp</H>
        <p>
          De ontwerpfase draait om het definiëren van welke interacties moeten worden gemeten samen met welke dimensies.
        </p>
        <p>
          Alle attributen van de bezoeker, sessie, pagina, product of gebeurtenis kunnen van potentieel belang zijn tijdens
          data-analyse en moeten worden overwogen voor de data laag architectuur.
        </p>
        <p>
          Om te beslissen wat moet worden opgenomen, begin je met het eind in gedachten en vraag je jezelf af welke
          bedrijfskritische vragen moeten worden beantwoord en beperk je tot de gerelateerde datapunten.
        </p>
        <p>
          De volgende stap is om uit te zoeken hoe die datapunten moeten worden gestructureerd en welke dimensies het
          belangrijkst zijn om toe te voegen.
        </p>
        <p>
          <b>Voorbeeld</b>: Een talenschool die een WordPress-website in meerdere talen beheert, wil de moedertaal van
          hun websitebezoekers weten en welke vreemde taal ze het meest interesseren. Het doel is om mogelijk online
          advertenties te plaatsen via Facebook Ads gericht op demografieën met vergelijkbare attributen.
        </p>
        <p>
          Als volgende stap moeten we alle relevante data definiëren voor verschillende soorten pagina's (homepage, cursuspagina's, over ons, contact en nieuws). Om te vereenvoudigen, kijken we naar de meest interessante pagina's en richten we ons op de homepage en cursuspagina's.
        </p>
        <ImgContainerFixed width="400px">
          <ImgScreenshot
            src="data-layer/data-layer-example-language-school.png"
            alt="Voorbeeld van Data Laag ontwerp voor een talenschool website"
            caption="Voorbeeld van een data laag ontwerp voor de homepage en cursuspagina's van een talenschool"
          />
        </ImgContainerFixed>
        <p>
          <b>Voorbeeld</b>: Array-gebaseerde Google Tag Manager data laag voor een talenschool
        </p>
        <SnippetJS caption="Code snippet om een GTM data laag te initiëren voor een talenschoolvoorbeeld. Merk op dat getallen worden gedeclareerd als strings.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": "nl", //Taal van de UI
  "sessionDuration": "182", //Sessie duur in seconden
  "languageIntent": "es", //meest bezochte cursus taal
  "pageType": "cursuspagina",
  "courseName": "Spaans A1 - Beginner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": "6" //Duur in weken
}];
`}
        </SnippetJS>
        <p>
          <b>Voorbeeld</b>: Object-gebaseerde data laag voor Adobe Launch
        </p>
        <SnippetJS caption="Initialisatie van een data laag voor Adobe Launch of Adobe DTM.">
          {`
window.digitalData = window.digitalData || {
  "language": "nl", //Taal van de UI
  "sessionDuration": 182, //Sessieduur in seconden
  "languageIntent": "es", //Meest bezochte cursus taal
  "pageType": "cursuspagina",
  "courseName": "Spaans A1 - Beginner",
  "courseLang": "es",
  "courseLevel": "a1",
  "courseDuration": 6 //Duur in weken
};
`}
        </SnippetJS>
        <H as="h3">2. Implementatie</H>
        <p>
          Data lagen moeten op elke subpagina van een website worden geïmplementeerd. De bovenstaande codevoorbeelden
          tonen echter de uiteindelijke <b>berekende staat</b>.
        </p>
        <p>
          Tijdens de implementatie moeten de datapunten eerst worden verzameld om te worden berekend naar hun
          uiteindelijke staat, dus de daadwerkelijke bron ziet er iets anders uit.
        </p>
        <p>Om een realistisch voorbeeld te geven, ga ik echter uit van het volgende:</p>
        <ul>
          <li>
            Sessieduur en taalinteresse worden verzameld via een zelfgemaakte JavaScript en opgeslagen in de lokale
            opslag van de browser.
          </li>
          <li>
            Taal, paginatype en cursusgegevens kunnen worden opgehaald uit de database via de serverrespons en
            beschikbaar worden gesteld op de cursussjablonen en de homepage.
          </li>
        </ul>
        <p>De broncode van de data laag in de backend volgens bovenstaande veronderstellingen zou er zo uitzien:</p>
        <SnippetJS caption="Voorbeeld broncode voor een data laag voor berekening.">
          {`
window.dataLayer = window.dataLayer || [{
  "language": <?php echo wpb_getpagedata("lang"); ?>,
  "sessionDuration": window.localStorage.sessionDuration,
  "languageIntent": window.localStorage.languageIntent
  "pageType": <?php echo wpb_getpagedata("type"); ?>,
  "courseName": <?php echo wpb_getcoursedata("name"); ?>,
  "courseSprache": <?php echo wpb_getcoursedata("lang"); ?>,
  "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
  "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
}];
`}
        </SnippetJS>
        <H as="h4">Event tracking met data laag push</H>
        <p>Om events naar een GTM data laag te sturen, kun je de push-methode gebruiken en letterlijk events erin pushen.</p>
        <SnippetJS caption="Voorbeeld van data laag push voor event tracking">
          {`
window.dataLayer.push({
  "event": "cursus-boeking", 
  "startWeek": "24"
});
`}
        </SnippetJS>
        <p>
          Het <b>event</b> sleutelwoord is een speciaal sleutelwoord en kan worden aangesproken als een <b>custom event</b> vanuit de GTM-container.
        </p>
        <p>
          Het tag management systeem observeert de data laag en voert een tag uit zodra een vooraf gedefinieerde custom event
          naar het systeem wordt verzonden.
        </p>
        <p>Nadat een event is toegevoegd, kan het TMS bijvoorbeeld een event naar Google Analytics sturen.</p>
        <p>
          Alle relevante data om context te bieden (naam, taal, taalniveau, cursusduur) is beschikbaar en kan samen met
          het event worden verzonden, bijvoorbeeld de startweek voor de cursus.
        </p>
        <p>
          In een object-gebaseerde data laag zou hetzelfde event direct naar Adobe Analytics worden gestuurd via hun eigen event
          trackingbibliotheek.
        </p>
        <p>Voor Adobe Launch zou de voorbeeldcode er zo uitzien:</p>
        <SnippetJS caption="Adobe Launch voorbeeld voor event tracking. Merk op dat de data direct naar Adobe Analytics wordt gestuurd zonder eerst door de data laag te gaan">
          {`
_satellite.setVar("startWeek", 24);
_satellite.track("cursus-boeking");
`}
        </SnippetJS>
        <H as="h4">Code positionering in de broncode</H>
        <p>
          De data laag code moet worden toegevoegd in de <code>{`<head>`}</code> van de pagina vóór het tag management systeem.
        </p>
        <p>
          Door deze volgorde zorg je ervoor dat het al is berekend wanneer het tag management systeem er toegang toe wil hebben.
        </p>
        <p>
          <b>Voorbeeld</b>: Positionering in de broncode
        </p>
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positionering van de data laag vóór de TMS tag in de broncode"
          caption="De data laag moet worden gepositioneerd in de <head> sectie vóór het tag management systeem, om ervoor te zorgen dat de data al is geladen wanneer het TMS er toegang toe wil hebben."
        />
        <H as="h3">3. Debugging</H>
        <p>
          De meest voorkomende procedures om een data laag te debuggen zijn het simuleren van paginaladingen of events om te verifiëren dat alle datapunten worden gevuld met de juiste data.
        </p>
        <p>
          Omdat het globaal toegankelijk is in de browserconsole, kun je eenvoudig alle waarden naar de console printen
          (ervan uitgaande dat standaard naamgevingsconventies worden toegepast):
        </p>
        <p>
          <b>Websites met GTM</b>
        </p>
        <p>
          <code>Object.assign(...dataLayer)</code>
        </p>
        <p>
          <b>Websites met Adobe Launch of DTM</b>
        </p>
        <p>
          <code>digitalData</code>
        </p>
        <p>
          <b>Tealium</b>
        </p>
        <p>
          <code>utag.data</code> of <code>utag_data</code>
        </p>
        <p>
          Google Tag Manager heeft zelfs een eigen <strong>Debugger Mode</strong>. Je kunt deze activeren vanuit de GTM
          interface door rechtsboven op <b>preview</b> te klikken.
        </p>
        <ImgScreenshot
          src="data-layer/gtm-activate-debugger-mode.png"
          alt="Screenshot van Google Tag Manager met de knop om de debugmodus te activeren"
          caption={`Je kunt de debugger-modus van GTM activeren door op de preview-knop te klikken. Als je vervolgens je website bezoekt met de geïnstalleerde GTM-container, verschijnt het debugger-venster onderaan de browser.`}
        />{" "}
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode.png"
          alt="screenshot van het GTM debugger-venster"
          caption="Je kunt alle data laag variabelen inspecteren en hun huidige waarden zien terwijl je met de website interacteert. Bovendien kun je de volgorde van binnenkomende events bekijken. Klik op elk event om te zien welke wijzigingen het event heeft veroorzaakt."
        />
        <p>
          Als je geen toegang hebt tot de tag management container maar deze toch wilt debuggen, kun je een{" "}
          <b>chrome-extensie</b> gebruiken.
        </p>
        <H as="h4">Data Laag Chrome-extensies</H>
        <p>
          Er is een verscheidenheid aan mogelijke extensies voor debugging beschikbaar. Ik geef de voorkeur aan degene die de meeste
          leveranciers ondersteunen, zodat ik niet tussen extensies hoef te wisselen bij het debuggen van een andere site.
        </p>
        <p>De volgende Chrome-extensies zijn momenteel mijn favorieten voor debugging:</p>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
            >
              <b>Trackie</b>
            </a>{" "}
            - Extensie gebaseerd op{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
            >
              Data Slayer
            </a>{" "}
            en open source. Het heeft een goede prestatie en ondersteunt GTM, DTM, Tealium{" "}
            <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
              en nog veel meer
            </a>
            .
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
            >
              <b>Omnibug</b>
            </a>{" "}
            - Nog een alleskunner met ondersteuning voor Adobe Analytics (DTM & Launch), evenals Matomo, GTM, Tealium{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
            >
              en meer
            </a>
            .
          </li>
        </ul>
        <H as="h4">Chrome-extensies voor het debuggen van Google Analytics en GTM</H>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
              <b>GTM/GA Debug</b>
            </a>{" "}
            - na het activeren van de extensie zal er een nieuw tabblad beschikbaar zijn in Chrome DevTools zodra je deze opent
            (F12 op Windows en CTRL+SHIFT+i op Mac). Alle relevante data wordt weergegeven in een grafische UI en wordt bijgewerkt
            terwijl je een website bekijkt.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
              <b>Adswerve - dataLayer Inspector+</b>
            </a>{" "}
            - logt alle relevante datapunten in de browserconsole. Activeer "preserve log" in de console-instellingen
            om logs te behouden tijdens paginanavigatie.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
              <b>Data Slayer</b>
            </a>{" "}
            - zeker de extensie met de coolste naam en logo en mijn vorige favoriet. Het is open source
            met een eenvoudige layout en werkt ook met Adobe DTM. <br />
            <u>
              Vink “gebruik drie-koloms layout waar beschikbaar”, “toon GA Classic tags” en “toon Floodlight tags” uit in
              de extensie-instellingen,
            </u>{" "}
            anders worden de logs een beetje rommelig.
          </li>
        </ul>
        <H as="h4">Chrome-extensies voor het debuggen van Adobe Analytics, Launch en DTM</H>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
            >
              <b>Launch & DTM Switch</b>
            </a>{" "}
            - stelt je in staat om de staging- of productiebibliotheek van het tag management systeem te laden en kan de
            debugmodus activeren.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
            >
              <b>Debugger voor Adobe Analytics</b>
            </a>{" "}
            - activeert de debugmodus. Als alternatief kun je ook <code>_satellite.setDebug(true)</code> in de console typen.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
            >
              <b>Adobe Experience Cloud Debugger</b>
            </a>{" "}
            - meta-extensie om alle Adobe-producten te debuggen.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
            >
              <b>Adobe Experience Platform Debugger</b>
            </a>{" "}
            - opvolger van de experience cloud debugger die een beter overzicht biedt (momenteel nog in bèta).{" "}
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
            >
              <b>Tealium Data Layer Debugger</b>
            </a>{" "}
            - eenvoudige tabellen van alle huidige waarden.
          </li>
        </ul>
        <H as="h2">E-commerce Data Laag</H>
        <p>
          Data lagen voor e-commerce zijn uitgebreider en hun structuur is complexer. Ze moeten meer data bevatten en meer
          events beheren.
        </p>
        <p>Daarom neemt de planning en implementatie van een e-commerce website aanzienlijk meer tijd in beslag.</p>
        <p>
          Het e-commerce rapport in Google Analytics, bijvoorbeeld, toont geen gegevens als de implementatie hun{" "}
          <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=nl">
            e-commerce data laag documentatie
          </a>{" "}
          niet volgt.
        </p>
        <p>
          Grote e-commerce winkels vereisen echter nog geavanceerdere trackingopstellingen. Ze implementeren een{" "}
          <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
            data laag voor geavanceerde e-commerce
          </a>
          , wat nog meer functionaliteit in Google Analytics mogelijk maakt.
        </p>
        <p>
          Je moet de implementatierichtlijnen voor Google Analytics strikt volgen om de e-commerce rapporten te laten werken. Dat betreft de structuur van de data laag en ook de variabelenamen.
        </p>
        <p>
          Als je een ander analytics platform kiest voor e-commerce, ben je vrij om de structuur naar eigen inzicht te plannen.
        </p>
        <H as="h2">Wanneer is een data laag niet nodig?</H>
        <p>
          Zoals met alles, zijn er ook gevallen waarin de extra inspanning van het implementeren van een data laag niet gerechtvaardigd is.
        </p>
        <p>
          In de bovenstaande voorbeelden hebben we gekeken naar gevallen waarin we data uit verschillende databronnen haalden (Frontend, Backend, API) en problemen oplosten die zich voordeden bij het werken met een verscheidenheid aan databronnen.
        </p>
        <p>
          Veel websites (zogenaamde brochure-websites) hebben echter niet eens een inlogfunctionaliteit en hebben ook geen database.
        </p>
        <p>
          Een andere belangrijke factor is hoe vaak er wijzigingen op de website worden doorgevoerd. Veel sites beoordelen zelden hun inhoud of voegen regelmatig functionaliteit toe. Ik zie zelfs ondernemingen eenvoudige brochure-websites draaien met ongeveer 50 subpagina's en een contactformulier als de belangrijkste conversie.
        </p>
        <p>
          Aangezien dergelijke sites waarschijnlijk alleen data van de frontend nodig hebben om hun data-analyse uit te voeren, kunnen ze volstaan met een eenvoudige analytics opstelling zonder data laag. Het zou de verzameling niet veel robuuster of veiliger maken, dus de voordelen worden gemitigeerd. Onder dergelijke omstandigheden rechtvaardigen de voordelen niet de extra inspanning van implementatie.
        </p>
        <p>
          Typische voorbeelden waarbij een data laag niet nodig is, zijn brochuresites of inhoudwebsites met een beperkt aantal of bijna geen harde conversies. Meestal zijn de eigenaren van dergelijke sites gewoon geïnteresseerd in het categoriseren van de gebruikersbetrokkenheid op basis van hun inhoudssecties of enkele bedrijfsinterne classificaties.
        </p>
        <p>
          Dergelijke vereisten kunnen worden bereikt met geavanceerde JavaScript en een goed doordacht systeem voor het structureren van de inhoud.
        </p>
        <p>
          Zodra de dataverzameling van de frontend regelmatig breekt en zeker wanneer er een database moet worden betrokken, wordt een data laag aanbevolen.
        </p>
        <p>
          Alternatieve oplossingen zijn vaak slechts tijdelijk bevredigend, vanwege steeds groeiende analytics ambities en regelmatig brekende dataverzameling. Ook zijn aangepaste oplossingen meestal moeilijk door te geven aan een ander bureau.
        </p>
        <p>
          Een data laag heeft goede kansen om de tand des tijds te doorstaan, omdat het al een gevestigd concept is in de web analytics sector, dus webbureaus hebben toenemende ervaring met het implementeren en onderhouden ervan.
        </p>
        <H as="h2">Conclusie</H>
        <p>
          Een datalaag is de gouden standaard voor professionele analytische opstellingen. Het verhoogt de datakwaliteit en
          verbetert daardoor de gegevensanalyse als geheel, terwijl het voldoet aan de beveiligingseisen.
        </p>
        <p>
          Voor ambitieuze website-eigenaren die willen beginnen met serieuze gegevensanalyse, is het de gemakkelijkste en meest
          robuuste oplossing.
        </p>
        <p>
          Als je de keuze hebt, implementeer dan een op array gebaseerde structuur, aangezien je minder afhankelijkheden hebt
          en het op alle soorten websites kunt gebruiken.
        </p>
        <p>
          Contentwebsites hebben echter zo weinig functionaliteit en brengen meestal slechts een beperkte hoeveelheid harde
          conversies met zich mee, waardoor een datalaag mogelijk kan worden verwaarloosd. Dit geldt vooral als alle benodigde
          gegevens beschikbaar zijn op de pagina's of beschikbaar kunnen worden gemaakt via enkele omwegen.
        </p>
        <p>
          Als je er zelf een wilt implementeren, is het waarschijnlijk het gemakkelijkst om dit met een WordPress-website te doen.
          Alle geavanceerde vereisten zijn echter waarschijnlijk niet de tijd en het risico van een onbevredigend resultaat waard.
          <br />
          Daarom is implementeren met de hulp van een analytics-consultant meestal de juiste weg, omdat het tijd bespaart en
          onnodige risico's vermijdt.
        </p>
        <p>
          Ik raad je aan om een van de genoemde Chrome-extensies te installeren om de datalagen van enkele grotere websites
          te inspecteren. Dit is meestal een geweldige inspiratiebron en levert enkele interessante KPI's op om mogelijk
          te integreren in je eigen analytische opstellingen 😉.
        </p>
        <H as="h2">Datalaagdocumentatie van verschillende TMS-leveranciers</H>
        <ul>
          <li>
            <b>Google Tag Manager</b>:{" "}
            <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
              Initiëren en gegevens wijzigen
            </a>
          </li>

          <li>
            <b>Adobe Launch</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.adobe.com/content/help/en/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
            >
              Initiëren
            </a>
          </li>

          <li>
            <b>Tealium iQ</b>:{" "}
            <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
              Initiëren
            </a>{" "}
            en{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
            >
              Gegevens wijzigen
            </a>
          </li>


          <li>
            <b>Matomo</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
            >
              Initiëren
            </a>{" "}
            en{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
            >
              gegevens wijzigen
            </a>
          </li>

          <li>
            <b>Piwik Pro</b>:{" "}
            <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
              Initiëren
            </a>{" "}
            en{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
            >
              gegevens wijzigen
            </a>
          </li>
        </ul>
        <H as="h2">FAQ</H>
        <FaqComponent data={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Wat is een voorbeeld van een datalaag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Een voorbeeld van een datalaag wordt in het artikel gegeven. Een JavaScript-object slaat gegevens van een website, database of een externe bron op in een centrale, flexibele en gemakkelijk toegankelijke manier. Een voorbeeld van een codefragment voor het initiëren van een datalaag voor Google Tag Manager is: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"category page\", \"pageName\": \"sneaker overzicht\", \"language\": \"nl-NL\",}];"
              }
            },
            {
              "@type": "Question",
              "name": "Wat zijn datalaagvariabelen?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Datalaagvariabelen zijn sleutel-waardeparen binnen de datalaag die specifieke informatie opslaan. Deze variabelen kunnen paginakentmerken, gebruikersgedragsgegevens en meer bevatten, en dienen als een centrale gegevensrepository voor analytics en tracking."
              }
            },
            {
              "@type": "Question",
              "name": "Waarom een datalaag gebruiken?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Een datalaag is essentieel voor robuuste, flexibele en veilige gegevensverzameling. Het centraliseert gegevens van verschillende bronnen, waardoor ze gemakkelijk toegankelijk en consistent zijn over verschillende webpagina's en gebruikersinteracties. Deze aanpak verbetert de gegevenskwaliteit en betrouwbaarheid, wat cruciaal is voor datagestuurde besluitvorming."
              }
            },
            {
              "@type": "Question",
              "name": "Heb ik een datalaag nodig?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hoewel het niet altijd noodzakelijk is, wordt een datalaag over het algemeen geadviseerd voor diegenen die serieus zijn over hun gegevensanalyse-ambities. Het biedt gegevenskwaliteit, betrouwbaarheid en tijdsbesparingen op de lange termijn die de hogere implementatie-inspanningen rechtvaardigen."
              }
            },
            {
              "@type": "Question",
              "name": "Wat zijn de voordelen van een datalaag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "De voordelen van een datalaag zijn onder andere: Beschikbaarheid van gegevens, ongeacht de zichtbaarheid ervan op de pagina. Robuuste gegevensverzameling. Vermindering van gegevensverlies bij asynchrone gegevensaanvragen. Veilige gegevensverzameling uit meerdere bronnen."
              }
            },
            {
              "@type": "Question",
              "name": "Hebben alle websites een datalaag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Niet alle websites hebben een datalaag. De implementatie ervan hangt af van de complexiteit van de website en de diepgang van de vereiste gegevensanalyse. Eenvoudige websites hebben mogelijk geen datalaag, terwijl complexere sites, vooral die gericht zijn op datagestuurde besluitvorming, dit waarschijnlijk wel hebben."
              }
            },
            {
              "@type": "Question",
              "name": "Hoe krijg ik toegang tot de datalaag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "De datalaag is wereldwijd toegankelijk in de browserconsole. Voor websites met Google Tag Manager kun je er toegang toe krijgen met dataLayer of Object.assign(...dataLayer). Voor Adobe Launch of DTM kun je er toegang toe krijgen met digitalData."
              }
            },
            {
              "@type": "Question",
              "name": "Hoe voeg je gegevens toe aan de datalaag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Om gegevens toe te voegen aan de datalaag, gebruik je de methode dataLayer.push(). Bijvoorbeeld: window.dataLayer.push({ \"event\": \"course-booking\", \"startWeek\": \"24\" }); Deze methode wordt gebruikt om nieuwe gegevens of wijzigingen toe te voegen aan de datalaag. De event-sleutel kan worden gebruikt om een andere tag-uitvoering in het tag-beheersysteem te activeren."
              }
            },
            {
              "@type": "Question",
              "name": "Wat zijn de voordelen van een datalaag?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "De voordelen van een datalaag zijn onder andere: Beschikbaarheid van gegevens, ongeacht de zichtbaarheid ervan op de pagina. Robuuste gegevensverzameling. Vermindering van gegevensverlies bij asynchrone gegevensaanvragen. Veilige gegevensverzameling uit meerdere bronnen."
              }
            }
          ]
        }
        } />
        <H as="h2">Verdere Bronnen</H>
        <ul>
          <li>
            Simo Ahava over de{" "}
            <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
              datalaag in GTM
            </a>{" "}
            en{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
            >
              hoe je gegevens hierin beheert.
            </a>
          </li>
          <li>Lees mijn <Link to="/nl/google-tag-manager-setup">Google Tag Manager handleiding</Link> en leer hoe je het instelt.</li>
          <li>
            Kevin Haag’s presentatie van Measurecamp Berlin 2019 over de{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
            >
              Event Driven Data Layer in Adobe Analytics
            </a>
          </li>
        </ul>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default DataLayer;